import { TextField } from '@mui/material'

const EmailField = ({
  id,
  placeholder,
  variant,
  size,
  error,
  register,
  name,
  disabled,
}) => {
  return (
    <TextField
      fullWidth
      id={id}
      placeholder={placeholder}
      variant={variant}
      size={size}
      error={!!error}
      disabled={disabled}
      inputProps={{ style: { textTransform: 'lowercase' } }}
      {...(register && register(name, { setValueAs: (e) => e?.toLowerCase() }))}
    />
  )
}

export default EmailField

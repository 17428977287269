import React, { useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Button, FormControl, FormHelperText, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginValidationSchema } from '@utils/validators'
import Loader from '@components/ui/loader'

import logo from 'public/logo.svg'
import loginImg from 'public/login-img.png'
import EmailField from './email-field'

const LoginForm = ({
  forgotHref,
  onSubmit,
  error,
  loading,
  description,
  hideImg,
}) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(LoginValidationSchema),
    defaultValues: {},
  })

  useEffect(() => {
    if (error) {
      setError('email', { type: 'server', ...error })
    }
  }, [error])
  return (
    <form
      className="min-h-screen flex items-center justify-center"
      onSubmit={handleSubmit((x) => {
        onSubmit(x)
      })}
    >
      <div className="p-10 shadow rounded-md flex flex-col justify-center max-w-sm max-w-full w-96">
        <div className="w-40 mx-auto">
          <Image src={logo} alt="logo" />
        </div>
        {/* <h1 className="pt-6 text-lg font-bold leading-7 text-center">Spaze</h1>
        <p className="pt-6 text-center text-sm font-medium">{description}</p>
        {hideImg ? (
          ''
        ) : (
          <div className="pt-6">
            <Image
              className="rounded-lg overflow-hidden"
              src={loginImg}
              alt="Food image"
            />
          </div>
        )} */}
        <div className="pt-6">
          <FormControl fullWidth>
            <label htmlFor="email" className="text-xs font-medium">
              Email
            </label>
            <EmailField
              id="email"
              placeholder=""
              variant="outlined"
              size="small"
              error={!!errors.email}
              register={register}
              name="email"
            />
            <FormHelperText error={!!errors.email} id="email">
              {errors.email?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="pt-6">
          <FormControl fullWidth>
            <label htmlFor="password" className="text-xs font-medium">
              Password
            </label>
            <TextField
              id="password"
              type="password"
              placeholder=""
              variant="outlined"
              size="small"
              error={!!errors.password}
              {...register('password')}
            />
            <FormHelperText error={!!errors.password} id="password">
              {errors.password?.message}
            </FormHelperText>
          </FormControl>
          <div className="text-right text-sm text-spazeOrange pt-1">
            <Link href={forgotHref}>Forgot your password?</Link>
          </div>
        </div>
        <div className="pt-6 flex justify-center">
          <Button fullWidth type="submit" color="primary" variant="contained">
            Log In
            <Loader loading={loading} />
          </Button>
        </div>
      </div>
    </form>
  )
}

export default LoginForm

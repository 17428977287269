import React from 'react'
import { CircularProgress } from '@mui/material'

export default function LoaderComponent({ loading }) {
  return (
    <React.Fragment>
      {loading ? (
        <span className="flex items-center ml-2">
          <CircularProgress color="inherit" size="1.5rem" />
        </span>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

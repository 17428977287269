const languagesList = [
  'English',
  'Polish',
  'Panjabi',
  'Urdu',
  'Bengali (with Sylheti and Chatgaya)',
  'Gujarati',
  'Arabic',
  'French',
  'Chinese (Madarin, Cantonese, other)',
  'Portuguese',
  'Russian',
  'Spanish',
  'Tamil',
  'Turkish',
  'Italian',
  'Somali',
  'Lithuanian',
  'German',
  'Persian/Farsi',
  'Tagalog/Filipino',
  'Romanian',
]

export default languagesList

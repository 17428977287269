import * as yup from 'yup'
import languagesList from './languages-list'

export const postCodeValidator =
  /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) \d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} \d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/
export const organisationNameValidator = /^[a-zA-Z0-9_-\s]+$/
export const onlyNumber = /^\d+$/

// TODO add custom, more readable error messages
export const TimeIntervalValidationSchema = yup.object().shape({
  days: yup
    .array()
    .of(yup.number())
    .min(1, "Please choose your organisation's working days"),
  startTime: yup
    .string()
    .nullable(true)
    .required('Organisation opening time is required'),
  endTime: yup
    .string()
    .nullable(true)
    .required('Organisation closing time is required'),
})

export const passwordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

export const FoodbankValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required()
    .matches(
      organisationNameValidator,
      'Organisation name cannot contain special symbols'
    ),
  address: yup
    .string()
    .required(
      'The address must not be empty and must be selected from the drop-down list'
    ),
  serviceTimes: yup.array().of(TimeIntervalValidationSchema).min(0),
  donationTimes: yup.array().of(TimeIntervalValidationSchema).min(0),
  volunteerPassword: yup.string().required(),
  email: yup
    .string()
    .default(undefined)
    .when(['published'], (isPublished, schema) => {
      return !isPublished ? schema : schema.email().required()
    }),
  eligibility: yup
    .string()
    .default(undefined)
    .when(['published'], (isPublished, schema) => {
      return !isPublished ? schema : schema.required()
    }),
  urgentItems: yup
    .string()
    .default(undefined)
    .when(['published'], (isPublished, schema) => {
      return !isPublished ? schema : schema.required()
    }),
})

export const ManagerValidationSchema = yup.object().shape({
  fullName: yup.string().required(),
  password: yup
    .string()
    .when(['invitationRequested'], (invitationRequested, schema) =>
      !invitationRequested
        ? yup.string().required('Password is required')
        : schema
    ),
  confirmPassword: yup
    .string()
    .when(['invitationRequested'], (invitationRequested, schema) =>
      !invitationRequested
        ? yup
            .string()
            .test('passwords-match', 'Passwords must match', function (value) {
              return this.parent.password === value
            })
        : schema
    ),
  email: yup.string().email().required(),
  terms: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions'),
})

export const SetupManagerAccountValidationSchema = yup.object().shape({
  fullName: yup.string().required('Full name is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

export const ManagerEditValidationSchema = yup.object().shape({
  fullName: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string(),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

export const СustomerValidationSchema = yup.object().shape({
  clients: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required(),
      governmentId: yup.string().trim().required(),
      dateOfBirth: yup.string().trim().required(),
      country: yup.string().trim().nullable(true),
      gender: yup.string(),
      genderOtherText: yup
        .string()
        .trim()
        .nullable(true)
        .when(['gender'], (gender, schema) =>
          gender === 'other'
            ? schema.required("Gender 'other' text is required")
            : schema
        ),
      city: yup.string().trim().nullable(true),
      phone: yup.string().trim().required(),
      email: yup.string().email(),
    })
  ),
})

export const LoginValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export const VolunteerLoginValidationSchema = yup.object().shape({
  username: yup.string().required(),
  passcode: yup.string().required(),
  terms: yup.boolean().oneOf([true], 'You must accept the end user terms'),
})

export const DatePeriodValidationSchema = yup.object().shape({
  dateTo: yup
    .date()
    .when(['allTime'], (isAllTimeSelected, schema) =>
      !isAllTimeSelected
        ? schema
            .min(yup.ref('dateFrom'), "To date can't be before From date")
            .required('This field is required')
        : schema
    ),
  dateFrom: yup
    .date()
    .when(['allTime'], (isAllTimeSelected, schema) =>
      !isAllTimeSelected
        ? schema
            .max(yup.ref('dateTo'), "From date can't be after To date")
            .required('This field is required')
        : schema
    ),
})

export const ContactValidationSchema = yup.object().shape({
  name: yup.string().trim().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
})

const BooleanWithTransformFromEmptyString = yup
  .boolean()
  .transform((v) => v || false)
const NumberWithTransformToZero = yup.number().transform((v) => v || 0)

export const ImportClientValidationSchema = yup.object().shape({
  name: yup.string().trim().required(),
  postcode: yup.string().trim().required().matches(postCodeValidator, {
    message: 'Incorrect postcode format',
  }),
  phone: yup.string(),
  email: yup.string().email(),
  adultCount: NumberWithTransformToZero,
  children0to1: NumberWithTransformToZero,
  children2to4: NumberWithTransformToZero,
  children5to10: NumberWithTransformToZero,
  children11to18: NumberWithTransformToZero,
  dietaryRequirements: yup.string(),
  preferredLanguage: yup.string().oneOf([languagesList, '']),
  referredToUs: BooleanWithTransformFromEmptyString,
  employmentLoss: BooleanWithTransformFromEmptyString,
  benefitPenalties: BooleanWithTransformFromEmptyString,
  benefitDelays: BooleanWithTransformFromEmptyString,
  lossOfIncome: BooleanWithTransformFromEmptyString,
  unexpectedExpense: BooleanWithTransformFromEmptyString,
  livingExpense: BooleanWithTransformFromEmptyString,
  bills: BooleanWithTransformFromEmptyString,
  debts: BooleanWithTransformFromEmptyString,
  housingCrisis: BooleanWithTransformFromEmptyString,
  preferNotToSay: BooleanWithTransformFromEmptyString,
  otherAttendingReason: yup.string(),
  referrals: BooleanWithTransformFromEmptyString,
  employmentAdvice: BooleanWithTransformFromEmptyString,
  debtAdvice: BooleanWithTransformFromEmptyString,
  settlementGuidanceReferral: BooleanWithTransformFromEmptyString,
  wellbeingReferral: BooleanWithTransformFromEmptyString,
  benefitsSupport: BooleanWithTransformFromEmptyString,
  otherInterestedService: yup.string(),
})

export const CustomFieldValidationSchema = yup.array().of(
  yup.object().shape({
    label: yup.string().trim().required(),
    options: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().trim().required(),
        })
      )
      .when('fieldType', {
        is: (v) => v === 'textarea' || v === 'checkbox',
        then: yup.array().min(0),
        otherwise: yup.array().min(2),
      }),
  })
)

export const CustomFieldsSectionsValidationSchema = yup.object().shape({
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      customFields: CustomFieldValidationSchema,
    })
  ),
})

export const CreateCustomServiceValidationSchema = yup.array().of(
  yup.object().shape({
    dimensionUnit: yup.string().trim().required(),
    name: yup.string().trim().required(),
  })
)

export const CreateCustomServiceSectionsValidationSchema = yup.object().shape({
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      services: CreateCustomServiceValidationSchema,
    })
  ),
})

export const CustomServiceValidationSchema = yup.object().shape({
  customServices: yup
    .array()
    .of(
      yup.object().shape({
        service: yup.string().trim().required(),
        value: yup.number(),
      })
    )
    .test({
      message: 'At least one filled service is required',
      test: (val) => val?.some(({ value }) => !!value),
    }),
})

export const useCustomServiceValidationSchema = async (values, sections) => {
  try {
    const resultValue = await CustomServiceValidationSchema.validate(values)

    if (!sections?.length) {
      return { values, errors: {} }
    }

    const isValid = values?.sections.every((section, index) => {
      const mappedSectionFromConfig = sections[index]

      return section.customFields.every(({ value }, sectionIndex) => {
        const mappedCustomFieldFromConfig =
          mappedSectionFromConfig.customFields[sectionIndex]
        return mappedCustomFieldFromConfig.required ? !!value : true
      })
    })

    return {
      values: resultValue,
      errors: { ...(isValid || { isValid }) },
    }
  } catch (errors) {
    console.log(errors)
    return {
      values: {},
      errors: { errors: { customFields: errors } },
    }
  }
}

import Router from 'next/router'
import axios from 'axios'
import { USER_ROLES } from '@utils/constants'

class AuthService {
  urlsRoleMap = Object.freeze({
    admin: process.env.NEXT_PUBLIC_ADMIN_LOGIN,
    manager: process.env.NEXT_PUBLIC_MANAGER_LOGIN,
    volunteer: process.env.NEXT_PUBLIC_VOLUNTEER_LOGIN,
    user: process.env.NEXT_PUBLIC_CLIENT_LOGIN,
  })

  urlsForgotPassMap = Object.freeze({
    admin: process.env.NEXT_PUBLIC_ADMIN_FORGOT_PASSWORD,
    manager: process.env.NEXT_PUBLIC_MANAGER_FORGOT_PASSWORD,
  })

  urlsResetPassMap = Object.freeze({
    admin: process.env.NEXT_PUBLIC_ADMIN_RESET_PASSWORD,
    manager: process.env.NEXT_PUBLIC_MANAGER_RESET_PASSWORD,
  })

  redirectsRoleMap = Object.freeze({
    admin: '/login/admin',
    manager: '/login',
    volunteer: 'volunteer/login',
    user: '/login',
  })

  async loginAs(role, credentialsPayload) {
    const { data } = await axios.post(
      `${this.urlsRoleMap[role]}`,
      credentialsPayload
    )
    return data
  }

  async forgotPassword(role, payload) {
    const data = await axios.put(`${this.urlsForgotPassMap[role]}`, payload)
    return data
  }

  async resetPassword(role, token, credentialsPayload) {
    const data = await axios.put(
      `${this.urlsResetPassMap[role]}?token=${token}`,
      credentialsPayload
    )
    return data
  }

  async setupManagerPassword(token, credentialsPayload) {
    const data = await axios.put(
      `${process.env.NEXT_PUBLIC_MANAGER_SETUP_PASSWORD}?token=${token}`,
      credentialsPayload
    )
    return data
  }

  async setupManagerAccount(token, credentialsPayload) {
    const data = await axios.put(
      `${process.env.NEXT_PUBLIC_MANAGER_SETUP_ACCOUNT}?token=${token}`,
      credentialsPayload
    )
    return data
  }

  logoutAs(role, foodbankId) {
    const loginPageUrl =
      foodbankId && role === USER_ROLES.VOLUNTEER
        ? `/${foodbankId}/${this.redirectsRoleMap[role]}`
        : this.redirectsRoleMap[role]
    Router.push(`/logout?loginPage=${loginPageUrl || '/login'}`)
  }
}

const authService = new AuthService()

export default authService
